import { colors, getTypographyFontStyle } from "../../styles/global.style";

import styled from "styled-components";

const FooterLogo = styled.img`
  margin-bottom: 64px;
  width: 100px;
  height:auto;
  @media only screen and (min-width: 768px) {
    margin-bottom: 80px;
  }
`;

const FooterContainer = styled.div`
  ${getTypographyFontStyle({ variant: "small", weight: 400 })};
  background: ${colors.primary};
  color: ${colors.light};
  margin-top: 64px;
  padding: 24px;
  @media only screen and (min-width: 768px) {
    padding: 48px;
    margin-top: 236px;
    ${getTypographyFontStyle({ variant: "body1", weight: 400 })};
  }
`;

const FooterLinkContainer = styled.div`
  padding-left: 12px;
  padding-bottom: 8px;
  position: relative;
  ${getTypographyFontStyle({ variant: "body1", weight: 400 })};
  &&:after {
    content: "";
    position: absolute;
    top: -14px;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 1px solid ${colors.light};
  }

  }
`;

const FooterLink = styled.p`
  display: inline-block;
  text-decoration: none;
  color: ${colors.light};
  padding-right: 24px;
  @media only screen and (min-width: 768px) {
    padding-right: 64px;
  }

  &:hover {
    color: ${colors.secondary};
  }


`;

const FooterIconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 12px;
  justify-content: center;
  @media only screen and (min-width: 768px) {
    justify-content: flex-end;
  }
`;

const FooterIcon = styled.img`
  margin-left: 6px;
  padding-top: 36px;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    padding-top: 8px;
    width: 32px;
    height: 32px;
  }
`;

const FooterCopyRights = styled.div`
  padding-top: 32px;
  text-align: center;
`;

export {
  FooterContainer,
  FooterLinkContainer,
  FooterIconContainer,
  FooterCopyRights,
  FooterLogo,
  FooterLink,
  FooterIcon,
};
