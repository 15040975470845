import styled from "styled-components";
import { colors, getTypographyFontStyle, device } from '../../styles/global.style';

const IconCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    height: 450px;
    border-radius: 8px;
    background-color:#fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    `;

    const Icon = styled.img`
width: 100%;
height: auto;

    `;

    const IconDescription = styled.p`
    ${getTypographyFontStyle({ variant: 'body1', weight: 400 })}
    color: ${colors.lightdark};
    margin: 16px;
    text-align: center;
    @media ${device.md} {
        margin: 22px 0 32px;
        max-width: 600px;
    }
    `;



export { IconCardContainer, Icon, IconDescription };
