import React from "react";
import Banner from "../../components/Banner/Banner";
import {
  MainContainer,
  Description,
  IntroContainer,
  SecurityImg,
  HappyCustomers,
  CustomerGrowth,
  GrowthWrapper,
  ServiceContainer,
  Industries,
  Icon,
  IconWrapper,
} from "./landingPage.style";
import Heading from "../../components/Header/Heading";
import IconCard from "../../components/IconCard/IconCard";
import cctv from "../../assets/cctv.png";
import doorlock from "../../assets/smart-lock.png";
import electricfence from "../../assets/electric-fence.png";
import telephone from "../../assets/intercom.png";
import shield from "../../assets/hero-graph.svg";
import business from "../../assets/business.svg";
import church from "../../assets/church.svg";
import education from "../../assets/education.svg";
import health from "../../assets/health.svg";
import retail from "../../assets/retail.svg";
import government from "../../assets/government.svg";
import { colors } from "../../styles/colors";
import "./LandingPage.css";
import Accordion from "../../components/Accordion/Accordion";

export default function LandingPage() {

  const services = [
    {
      icon: cctv,
      title: "Video Security",
      description:
        "Enjoy total peace of mind with a customized video security solution that fully protects your employees and physical property.",
    },
    {
      icon: doorlock,
      title: "Access Control",
      description:
        "Automated perimeter security ensures safety for your business and employees, utilizing keycard that track who comes and goes, to prevent unauthorized entry.",
    },
    {
      icon: electricfence,
      title: "Electric Fence",
      description:
        "Protect your employees and property with a custom electric fence that shocks and raises alarm when intruders are detected.",
    },
    {
      icon: telephone,
      title: "Intercom Systems",
      description:
        "Enhance protection at the front door and beyond, with cutting edge video intercom systems that screen visitors and provide convenient two-way communication throughout your home or business.",
    },
  ];

  const accordionData = [
    {
      title: "How many security cameras do I need?",
      content: `The number of security cameras you need will depend on the nature of the problem or threat that the CCTV camera system needs to resolve. The first step is to mark critical areas of concern on a site plan. Next, define the problems affecting the premises, e.g. theft, unauthorised entry, health and safety etc. This process will give you an idea of the number of CCTV cameras required.`,
    },
    {
      title: "CCTV installation cost?",
      content: `The camera type defines the cost of CCTV installation, the number of cameras and any nuances to the installation. All projects vary, some are straightforward, and others are much more complex and require further planning and consideration. If you’d like to know more then, please contact our team. We provide a free consultant and site visit to determine your requirements and offer a fixed-no-obligation quote.`,
    },
    {
      title: "What is the best CCTV system for my business?",
      content: `The best CCTV system for your business depends on several factors. Ensuring as a minimum, we meet your security needs while taking into account budget and any technical constraints. We offer a free quote and no-obligation consultation to determine your exact requirements.

      In short, the best CCTV system for your business is one that can be realistically obtained based on your needs and circumstances.`,
    },
    {
      title: "How do I Operate my CCTV system?",
      content:
        "We'll walk you through all aspects of your security system, to ensure ease of operation and peace of mind.",
    },
    {
      title: "Can I watch my CCTV from my mobile device",
      content:
        "Yes, you can watch with your mobile device remotely to access multiple views of your property, from wherever you are. ",
    },
  ];

  const industries = [
    {
      icon: business,
      title: "Business Security",
      description:
        "From SMBs to large corporations, we design, install and service fully integrated business security solutions that protect your employees, assets and property from theft, vandalism, and unauthorized access.",
    },
    {
      icon: church,
      title: "Church Security",
      description:
        "We provide comprehensive video security and access control solutions for places of worship, to help protect congregants, clergy and staff members.",
    },
    {
      icon: education,
      title: "Education Security",
      description:
        "We provide comprehensive video security and access control solutions for schools, colleges and universities, to help protect students, staff and faculty members.",
    },
    {
      icon: health,
      title: "Health Security",
      description:
        "We provide comprehensive video security and access control solutions for health care facilities, to help protect patients, staff and visitors.",
    },
    {
      icon: retail,
      title: "Retail Security",
      description:
        "We provide comprehensive video security and access control solutions for retail and consumer shopping centres, to help protect customers, staff and visitors.",
    },
    {
      icon: government,
      title: "Government Security",
      description:
        "We provide comprehensive video security and access control solutions for government buildings, to help protect staff and visitors.",
    },
  ];
  return (
    <div>
      <Banner />
      <MainContainer>
        <div className=" mt-64 container">
          <div className="d-flex flex-column justify-content-center align-items-center flex-md-row">
            <div data-aos="fade-right">
              <HappyCustomers> 400 +</HappyCustomers>
              <Heading color={colors.lightdark}>Happy Customers</Heading>
            </div>

            <GrowthWrapper data-aos="fade-left">
              <CustomerGrowth>
                <Description data-aos="fade-in">
                  {" "}
                  Best In Class Security Solutions for your home and business
                  place.
                </Description>
              </CustomerGrowth>
              <SecurityImg src={shield} />
            </GrowthWrapper>
          </div>
        </div>
        <IntroContainer>
          <div  className="container">
            <Description data-aos="fade-right" color={colors.light}>
              Trixie group provides commercial, industrial and Schools customized
              security solutions. We believe that every business or organization
              has different needs and we don't use a "one size fits all"
              approach. 
            </Description>
            <Description data-aos="fade-right" color={colors.light}>
            Whether you need a surveillance, an alarm or an access
              control system, we design and engineer a solution that works with
              a customer's needs, operation and environment. Video Security
              Enjoy total peace of mind with a customized video security
              solution that fully protects your employees and physical property.
            </Description>
          </div>
        </IntroContainer>
        <ServiceContainer>
          <div className="mb-41 mt-64"></div>
          <div className="row  ">
            {services.map((service) => {
              return (
                <div
                  data-aos="fade-left"
                  key={service.title}
                  className="col-12 col-md-6 col-lg-3 mb-3  d-flex justify-content-center"
                >
                  <IconCard
                    icon={service.icon}
                    title={service.title}
                    description={service.description}
                  />
                </div>
              );
            })}
          </div>
        </ServiceContainer>
        <div className="container">
          <Industries>
            <Heading color={colors.dark}>key industries we serve</Heading>
            <div className="row  ">
              {industries.map((industry) => {
                return (
                  <div
                  data-aos="fade-right"
                    key={industry.title}
                    className="col-12 col-md-6 col-lg-4 mb-3  d-flex justify-content-center"
                  >
                    <div className="d-flex flex-column">
                      <section className="d-flex ">
                        <IconWrapper>
                          <Icon src={industry.icon} alt={industry.title} />
                        </IconWrapper>
                        <Heading color={colors.dark} as="h2">
                          {industry.title}
                        </Heading>
                      </section>
                      <section>
                        <Description>{industry.description}</Description>
                      </section>
                    </div>
                  </div>
                );
              })}
            </div>
          </Industries>
        </div>
        <div className=" container d-flex mt-64 mb-41 flex-column  justify-content-center align-items-center">
          <Heading data-aos="fade-left" as="h2" color={colors.primarydark}>
            YOU ASKED, WE LISTENED
          </Heading>
          <Heading data-aos="fade-right">Frequently Asked Questions</Heading>
        </div>

        <div className=" container">
          {accordionData.map((accordion) => (
            <Accordion
              key={accordion.title}
              title={accordion.title}
              content={accordion.content}
              
            />
          ))}
        </div>
      </MainContainer>
    </div>
  );
}
