import React from "react";
import { useNavigate } from "react-router-dom";
import { useDeviceMode } from "../../utils/DeviceMode";
import { NavItems, Logo } from "./Navbar.style";
import hamburger from "./assets/hamburger.png";
import "./Navbar.css";
import trixie from "../../assets/trixie.png";

export default function NavBar() {
  const { desktop } = useDeviceMode();
  const navigate = useNavigate();
  return (
    <nav className=" navbg fixed-top navbar navbar-expand-md justify-content-between   d-flex container-fluid">
      <NavItems
        className="navbar-brand  nav-text"
        data-bs-toggle={desktop ? "none" : "collapse"}
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => {
          navigate("/");
        }}
      >
        <Logo src={trixie} alt="logo" />
      </NavItems>

      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img src={hamburger} alt="hamburger" />
      </button>
      <div
        className="collapse justify-content-end navbar-collapse pt-2"
        id="navbarSupportedContent"
      >
        <ul className="d-flex flex-column flex-md-row  "> 
            <NavItems className="ms-3"
              data-bs-toggle={desktop ? "none" : "collapse"}
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact
            </NavItems> 
            <NavItems className="ms-3"
              data-bs-toggle={desktop ? "none" : "collapse"}
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                navigate("/about");
              }}
            >
              About
            </NavItems> 
        </ul>
      </div>
    </nav>
  );
}
