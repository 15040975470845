import styled from "styled-components";
import { colors, getTypographyFontStyle } from "../../styles/global.style";
const AccordionDescription = styled.p`
    ${getTypographyFontStyle({ variant: "body1", weight: 500 })};
    color: ${colors.lightdark};
`;

const AccordionAction = styled.div`
color: ${colors.dark};
cursor: pointer;
`;

export { AccordionDescription, AccordionAction };