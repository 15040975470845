import styled from 'styled-components';

import { colors, getTypographyFontStyle } from '../../styles/global.style';

const NavItems = styled.div`
  text-decoration: none;
  ${getTypographyFontStyle({ variant: 'body1', weight: 500 })};
  color: ${colors.primary};
  margin-left: 80px;
  cursor: pointer;
  &:active {
    font-weight: 900;
  }

  @media screen and (max-width: 768px) {
    margin-left: 32px;
    display: flex;
    height: 57px;
    align-items: center;
  }
`;

const Divider = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    height: 1px;
    width: 100%;
    background-color: ${colors.primarydark};
  }
`;
const Logo = styled.img`
width: 100px;
height:auto;
`
export  {NavItems, Divider,Logo};
