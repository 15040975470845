import { colors, getTypographyFontStyle } from '../../styles/global.style';

import styled from "styled-components";


const CustomInput = styled.input`
    background: white;
    border-radius: 4px;
    border: 1px solid ${props => props.noBorder ? 'transparent' : '#454545'};
    padding: 12px 40px 12px 24px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;

    ${getTypographyFontStyle({ variant: 'body3', weight: 400 })};
    &&:focus {
        border: 2px solid ${props => props.isError ? colors.danger : colors.primary};
        outline: none;
    }
    ${
        props => props.isError && `border-color: ${colors.danger};`
    }
    
`;


const InputLabel = styled.label`
color: ${colors.primary};
${getTypographyFontStyle({ variant: 'body3', weight: 400 })};
`;

const InputFieldContainer = styled.div`
    height:81px;
`;


export {
    CustomInput,
    InputFieldContainer,
    InputLabel
}