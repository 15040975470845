export const colors = {
  primary: '#135896',
  secondary: '#2EB2FF',
  tertiary: '#F4F4F4',
  primaryBg: ' rgba(19, 88, 150, 0.05)',
  primarydark: 'rgba(19, 88, 150, 0.75)',
  light: '#F4F4F4',
  danger: '#F63232',
  dark: '#222222',
  lightdark: '#333333',
  ligtblue: '#B9E5FF'

};
