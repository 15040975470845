const prod = {
    url: {
     API_URL: 'https://trixiegroup.com/api'
   }};
   
   const dev = {
    url: {
     API_URL: 'https://dev.trixiegroup.com/prod_sup/api'
    }
   };

   const  baseUrl = process.env.NODE_ENV === 'development' ? dev.url : prod.url;
   export default baseUrl;