import React from "react";
import Heading from "../../components/Header/Heading";
import { ContainerWrapper } from "./About.style";
import { Paragraph } from "./About.style";
export default function About() {
  return (
    <ContainerWrapper >
<Heading as="h2" >About Us</Heading>
<section>
<Paragraph>
        Trixie Group is an indigenous company registered with the primary aim of
        using technology to solve the daily security challenges faced by
        mankind. Our mission is to guard you, your family and your business from
        risk and provide peace of mind.
      </Paragraph>
      <Paragraph>
        We work with governments, large corporations, and small businesses at
        all levels globally to provide solutions that are budgeted for by their
        respective entities’ budgets.
      </Paragraph>
</section>
    </ContainerWrapper>
  );
}
