import styled from "styled-components";
import {
    device,
    colors,
    getTypographyFontStyle,
  } from "../../styles/global.style";

const ContainerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 56px;
    padding-left: 8%;
    padding-right: 8%;
    flex-direction: column;
    margin: 150px 0 200px 0;
    @media only screen and ${device.md} {
        margin: 150px 0;
    }
 
`;

const Paragraph = styled.p`
${getTypographyFontStyle({ variant: "body1", weight: 400 })};
color: ${((props) => props.color || colors.dark)};
    margin-bottom: 1rem;
    margin-top: 1rem;

`;



export {
    ContainerWrapper,
    Paragraph,
};