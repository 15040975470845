import { colors, getTypographyFontStyle, device } from '../../styles/global.style';

import styled from "styled-components";

const CustomTextArea = styled.textarea`
    background: white;
    border-radius: 4px;
    border: 1px solid ${props => props.noBorder ? 'transparent' : '#454545'};
    padding: 20px 16px;
    height: 142px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
    color: ${colors.primary};
    ${getTypographyFontStyle({ variant: 'body3', weight: 400 })};
    &&:focus {
        border: 2px solid ${props => props.isError ? colors.danger : colors.primary};
        outline: none;
    }
    ${
        props => props.isError && `border-color: ${colors.danger};`
    }
    
    @media ${device.md} {
        height: 150px;
    }
`;

const InputLabel = styled.label`
    color: ${colors.primary};
    ${getTypographyFontStyle({ variant: 'body3', weight: 400 })};
`;

const InputFieldContainer = styled.div``;


export {
    CustomTextArea,
    InputFieldContainer,
    InputLabel
}