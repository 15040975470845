import React from 'react';

import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import LandingPage from './pages/Landing-page/landingPage';
import Footer from './components/Footer/Footer';
import Contact from './pages/Contact/Contact';
import About from './pages/About/about';
import AOS from 'aos';


import './App.css';

function App() {
  AOS.init({
    offset:200,
    duration:1000,
  });

  return (
    <div>
      <Router>
      
        <Navbar />
        <div className="App">
          <Routes>
       <Route path="/" element={<LandingPage />} />
       <Route path="/contact" element={<Contact />} />
       <Route path="/about" element={<About />} />
          </Routes>

        </div>

        <Footer/>
      
      </Router>
    </div>
  );
}

export default App;
