import React, { useState } from 'react';
import { colors } from '../../styles/colors';
import Heading from '../Header/Heading';
import { AccordionAction,AccordionDescription  } from './Accordion.style';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div data-aos="fade-left" className="accordion-item-m">
      <div className="accordion-title-m d-flex justify-content-between" onClick={() => setIsActive(!isActive)}>
        <Heading color={colors.dark} as="h2">{title}</Heading>
        <AccordionAction >{isActive ? '-' : '+'}</AccordionAction>
      </div>
      {isActive && <AccordionDescription data-aos="fade-right" className="accordion-content-m">{content}</AccordionDescription>}
    </div>
  );
};

export default Accordion;