import React from 'react'
import { CustomTextArea, InputFieldContainer, InputLabel } from './TextArea.style';
import PropTypes from 'prop-types';
import FormError  from '../FormError/FormError';

export default function TextArea({ isError, errorMessage, label, ...rest }) {
    return (
  <>
      <InputLabel htmlFor={rest.name}>{label}</InputLabel>
      <InputFieldContainer>
        <CustomTextArea {...rest} isError={isError} />
        {
          isError && (<FormError message={errorMessage} />)
        }
      </InputFieldContainer>

    </>
    )
}


TextArea.propTypes = {
  /**
   * To hide and show the error message
   */
  isError: PropTypes.bool,

  /**
   * Display error message when it is enabled
   */
  errorMessage: PropTypes.string.isRequired,
  /**
   * Input label
   */
  label: PropTypes.string.isRequired
};

TextArea.defaultProps = {
  isError: false,
  errorMessage: 'Error Message',
  label: 'Input Label'
};