import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Button from "../../components/Button/Button";
import "react-toastify/dist/ReactToastify.css";
import {
  HeaderContainer,
  DefaultWidth,
  FormContainer,
  ContainerWrapper,
  RowColumn,
  InputContainer,
  SecondDefaultWidth,
} from "./Contact.style.js";
import InputField from "../../components/InputField/InputField";
import TextArea from "../../components/TextArea/TextArea";
import Heading from "../../components/Header/Heading";

import baseUrl from "../../api/config";

const CustomInputField = ({ field, form, ...props }) => {
  const fieldName = field.name;
  const errorMessage = form.errors[fieldName];
  const isTouched = form.touched[fieldName];
  return (
    <InputContainer>
      <InputField
        {...field}
        {...props}
        isError={!!isTouched && !!errorMessage}
        errorMessage={isTouched && errorMessage}
      />
    </InputContainer>
  );
};
const CustomTextArea = ({ field, form, ...props }) => {
  const fieldName = field.name;
  const errorMessage = form.errors[fieldName];
  const isTouched = form.touched[fieldName];
  return (
    <InputContainer>
      <TextArea
        {...field}
        {...props}
        isError={!!isTouched && !!errorMessage}
        errorMessage={isTouched && errorMessage}
      />
    </InputContainer>
  );
};

export default function Contact() {
  const [isSubmitted, setSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const formUrl = `${baseUrl.API_URL}/forms/form_02`;
  const submitForm = (values) => {
    setSubmitting(true);
    const bodyData = {
      msgToRecipient: {
        subject: "Message From Trixie Group",
        message: "Your message has been sent successfully, we will get back to you shortly",
        companyName: "Trixie Group",
      },
      companyName: "Trixie Group",
      adminEmailAddress: "info@trixiegroup.com",

      data: {
        name: values.name,
        emailAddress: values.email,
        message: values.message,
      },
    };

    fetch(formUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.json())
      .then((data) => {
        //clear form input
        values.name = "";
        values.email = "";
        values.message = "";

        console.log("Success:", data?.message);
        setResponseMessage(data?.message);
        toast.success(responseMessage);
        setSubmitting(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setResponseMessage(error?.message);
        toast.error(responseMessage);
        setSubmitting(false);
      });
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <ContainerWrapper>
        <RowColumn>
          <DefaultWidth></DefaultWidth>
          <SecondDefaultWidth>
            <HeaderContainer>
              <Heading as="h2">Get in touch</Heading>
            </HeaderContainer>
            <FormContainer>
              <Formik
                initialValues={{
                  email: "",
                  name: "",
                  message: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Invalid email address")
                    .required("Required"),
                  name: Yup.string().required("Required"),
                  message: Yup.string()
                    .max(200, "Must be 200 characters or less")
                    .required("Required"),
                })}
                onSubmit={(values) => {
                  submitForm(values);
                }}
              >
                <Form>
                  <Field
                    name="email"
                    type="email"
                    label="Your email"
                    placeholder="Enter your email"
                    component={CustomInputField}
                  />
                  <Field
                    name="name"
                    type="text"
                    label="Name"
                    placeholder="Enter your name"
                    component={CustomInputField}
                  />
                  <Field
                    name="message"
                    type="text"
                    label="Tell us something"
                    placeholder="Enter your message"
                    component={CustomTextArea}
                  />
                  <Button disabled={isSubmitted} label="Submit" type="submit" />
                </Form>
              </Formik>
            </FormContainer>
          </SecondDefaultWidth>
        </RowColumn>
      </ContainerWrapper>
    </>
  );
}
