import React from "react";
import trixie from "../../assets/trixie.png";

import {
  FooterContainer,
  FooterCopyRights,
  FooterIcon,
  FooterIconContainer,
  FooterLink,
  FooterLinkContainer,
  FooterLogo,
} from "./Footer.style";
import fb from "./assets/fb.svg"; 
import ig from "./assets/in.svg";

const Footer = () => {
  const socials = [
    {
      name: "facebook",
      icon: fb,
      url: "https://www.facebook.com/profile.php?id=100086018196742",
    },
    {
      name: "instagram",
      icon: ig,
      url: "https://www.instagram.com/trixiegroup/",
    },
  ];


  return (
    <FooterContainer>
      <div>
        <FooterLogo src={trixie} alt="logo"/>
        <FooterLinkContainer>
          <FooterLink>Ibadan, Nigeria</FooterLink>
          <FooterLink>No 9, Ire-akari Estate, Off Oluyole Ext.</FooterLink>
        </FooterLinkContainer>
        <FooterIconContainer>
          {socials.map((social) => (
            <a href={social.url} target="_blank" rel="noreferrer" key={social.name}>
              <FooterIcon src={social.icon} alt={social.name} />
            </a>
          ))}
        </FooterIconContainer>
        <FooterCopyRights>
          &copy; {new Date().getFullYear()} Trixie All rights reserved
        </FooterCopyRights>
      </div>
    </FooterContainer>
  );
};
export default Footer;
