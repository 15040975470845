import styled, { css } from "styled-components";
import {
  colors,
  getTypographyFontStyle,
  device,
} from "../../styles/global.style";

const buttonFontSize = {
  small: getTypographyFontStyle({ variant: "button2", weight: 400 }),
  medium: getTypographyFontStyle({ variant: "button2", weight: 400 }),
  large: getTypographyFontStyle({ variant: "button1", weight: 400 }),
};

const buttonPadding = {
  "sm-small": "12px 20.5px",
  "sm-medium": "12px 20.5px",
  "sm-large": "12px 34px",
  "md-small": "10px 30px",
  "md-medium": "10px 30px",
  "md-large": "10px 57.5px",
};
const PrimaryButton = styled.button`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  border-radius: 4px;
  background-color:${(props) => (props.disabled ? colors.primarydark : colors.primary)};
  color: ${colors.tertiary};
  display: inline-block;
  padding: 10px 50px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 4px;
  ${getTypographyFontStyle({ variant: "button1", weight: 400 })}
  border: none;
  padding: ${(props) => buttonPadding[`sm-${props.size}`]};
  @media ${device.md} {
    padding: ${(props) => buttonPadding[`md-${props.size}`]};
  }
  &&:hover {
    ${(props) =>
      !props.disabled &&
      css`
        background-color: ${(props) =>
          props.primary ? colors.primaryDark : colors.secondary};
      `}
    color:  ${(props) => (props.primary ? colors.darkGrey : colors.primary)};
  }
`;

export default PrimaryButton;
