import React from 'react'
import { IconCardContainer,Icon, IconDescription } from './IconCard.style';
import cctv from '../../assets/cctv.svg';
import Heading from '../Header/Heading';
import { colors } from '../../styles/colors';
export default function IconCard(props) {
    const { icon, title, description } = props;
  return (
   <IconCardContainer>
        <Icon src={icon} alt={title}/>

  <div className='mt-2'>
  <Heading as="h2" color={colors.dark}>
{title}
        </Heading>
  </div>
        <IconDescription>
        {description} 
        </IconDescription>
   </IconCardContainer>
  )
}

IconCard.defaultProps = {
    icon: cctv,
    title: 'CCTV Installation',
};