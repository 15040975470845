import styled from "styled-components";
import {
  colors,
  getTypographyFontStyle,
  device,
} from "../../styles/global.style";

const MainContainer = styled.div``;
const Description = styled.p`
  ${getTypographyFontStyle({ variant: "body1", weight: 500 })};
  color: ${((props) => props.color || colors.dark)};
  margin-top: 20px;
`;
const IntroContainer = styled.div`
  background-color: ${colors.primary};
  padding: 32px 0;
  margin-top: 200px;
  @media ${device.md} {
    padding: 58px 0;
  }
`;
const ServiceContainer=styled.div`
position:relative;
top:-50px;
`
const AccordionContainer = styled.div`
  background-color: #f8fbfd;
`;
const SecurityImg = styled.img`
  width: 200px;

  @media ${device.md} {
    width: 320px;
  }
`;
const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;

  @media ${device.md} {
    min-height: 500px;
  }
`;
const TestimmonialsContent = styled.p`
  ${getTypographyFontStyle({ variant: "body1", weight: 500 })};
  background: ${colors.tertiary};
  padding: 26px;
  color: #18191f;
  width: 320px;
  border-radius: 10px;
  @media ${device.md} {
    padding: 32px;
    width: 400px;
  }

  @media ${device.lg} {
    padding: 40px;
    width: 500px;
  }
`;
const Apostrophe = styled.span`
  ${getTypographyFontStyle({ variant: "body1", weight: 500 })};

  color: ${colors.primary};
  margin-right: 5px;
  @media ${device.md} {
    margin-right: 20px;
    font-size: 72px;
  }
`;

const TestimonialName = styled.p`
  ${getTypographyFontStyle({ variant: "body1", weight: 700 })};
  color: ${colors.lightdark};
`;

const TestimonialTitle = styled.p`
  ${getTypographyFontStyle({ variant: "body1", weight: 400 })};
  color: ${colors.lightdark};
`;
const HappyCustomers = styled.h1`
  font-size: 71px;
  color: ${colors.dark};
font-family: 'Poppins', sans-serif;
`;
const CustomerGrowth = styled.div`
  background-color: #fff;
  padding: 32px 0;
  border-radius: 24px;
  position: relative;
  top: 100px;
  left: 120px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;

  @media ${device.md} {
    width: 240px;
    height: 210px;
    top: 150px;
    left: 150px;
  }
`;
const GrowthWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-right: 50px;
  @media ${device.md} {
    margin-right: 0;
  }
`;

const Icon=styled.img`
width:40px;
height:40px;
`

const IconWrapper=styled.div`
display:flex;
justify-content:center;
align-items:center;
width:40px;
height:40px;
border-radius:50%;
margin-right:10px;
background-color:${colors.primary};
`
const Industries = styled.div`
`;
export {
  SecurityImg,
  AccordionContainer,
  MainContainer,
  Description,
  IntroContainer,
  CarouselContainer,
  TestimmonialsContent,
  Apostrophe,
  TestimonialName,
  TestimonialTitle,
  HappyCustomers,
  CustomerGrowth,
  GrowthWrapper,
  ServiceContainer,
  Industries,
  Icon,
  IconWrapper
};
