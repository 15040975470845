import styled from "styled-components";
import {
  colors,
  device,
} from "../../styles/global.style";

const BannerImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  min-height: 300px;
`;
const BannerContainer = styled.header`
  background: url(${(props) => props.bg});
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  object-fit: contain;
  background-position: top;
`;

const BannerBody = styled.div`
  padding-top: 150px;
  padding-left: 8%;
  padding-right: 8%;
  width: 80%;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media ${device.md} {
    padding-top: 100px;
  }
`;
const Container = styled.div`
  height: 100%;
`;
const BannerDescription = styled.p`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: ${colors.light};
  margin: 16px 0;
  @media ${device.lg} {
    font-size: 22px;
    margin: 22px 0 32px;
    max-width: 600px;
  }
`;
const Card = styled.div`
  position: relative;
  margin-right: 20px;
  top: -50px;
  @media ${device.md} {
    margin-right: 50px;
    top: -100px;
  }
`;
const BannerHeading = styled.h1`
  font-size: 34px;
  font-family: "Poppins", sans-serif;
  color: ${colors.light};
  @media ${device.lg} {
    font-size: 58px;
  }
`;
const Link = styled.a`
  text-decoration: none;
  color: ${colors.tertiary};
  &&:hover {
    color: ${colors.secondary};
  }
`;

export {
  Link,
  BannerDescription,
  Container,
  BannerImage,
  BannerContainer,
  BannerBody,
  Card,
  BannerHeading,
};
