import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import "./Banner.css";
import {
  Container,
  BannerBody,
  BannerContainer,
  BannerDescription,
  Card,
  BannerHeading,
} from "./Banner.style";
import Button from "../Button/Button";
import cctvbg from "./assets/cctv.png";

export default function Banner(props) {
  const { description, title } = props;
  const navigate = useNavigate();
  return (
    <Container>
      <BannerContainer bg={cctvbg}>
        <BannerBody>
          <BannerHeading>{title}</BannerHeading>
          <BannerDescription>{description}</BannerDescription>

          <Button
            onClick={() => {
              navigate("/contact");
            }}
            label="FREE CONSULTATION"
            format="outlined"
          />
        </BannerBody>
      </BannerContainer>
      <div className="d-flex justify-content-end w-100">
        <Card>
          <BannerDescription  data-aos="fade-right">
            Get a free quote for your project
          </BannerDescription>
        </Card>
      </div>
    </Container>
  );
}

Banner.defaultProps = {
  title: "ADVANCED ALARM, VIDEO SURVEILLANCE AND ACCESS CONTROL SYSTEMS",
  description:
    "We guarantee your total safety and happiness based on our years of providing standard security solution to both private and businesses",
};

Banner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
