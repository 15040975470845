import {
  device,
} from "../../styles/global.style";

import styled from "styled-components";

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
  @media only screen and ${device.md} {
    margin-top: 110px;
  }
`;

const RowColumn = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 56px;

  @media ${device.md} {
    margin-top: 0;
  }
`;
const InputContainer = styled.div`
  margin-bottom: 12px;
`;

const FormContainer = styled.div`
  margin-top: 22.5px;
  height: 100%;
  padding-left: 8%;
  padding-right: 8%;
`;

const DefaultWidth = styled.div`
  width: 100%;
  margin-top: 106px;
  @media ${device.md} {
    margin-top: 0px;
  }
`;
const SecondDefaultWidth = styled.div`
  width: 100%;
`;


export {
  SecondDefaultWidth,
  HeaderContainer,
  DefaultWidth,
  FormContainer,
  ContainerWrapper,
  RowColumn,
  InputContainer,
};
